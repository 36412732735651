.ui-message {
    display: grid;
    grid-template-columns: 60px auto 30px;
    background-color: #e9f5fe;
    padding: 20px;
    border-radius: 3px;
    align-items: center;
}

.ui-message__icon {
    color: #3498FF;
}

.ui-message__close {
    cursor: pointer;
    font-weight: bold;
}