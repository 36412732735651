.sc-word-count {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    background: transparent;
    gap: 1rem;
}
.sc-word-count-news {
    position: static;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 3px;
    background: transparent;
    gap: 1rem;
}

.sc-word-count__words, .sc-word-count__characters {
    color: #8e9cac;
}