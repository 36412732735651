/* .word-wrap-cell {
  word-wrap: break-all;
  white-space: normal;
}

.badge-padding {
  padding: 4px 4px !important;
}

.background-success {
  background: #59b15b !important;
}

.background-error {
  background: #f04f43 !important;
}

.smooth-border {
  border-radius: 10px !important;
} */

.sc_statistics {  
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 25px;
  background: #f1f3f4;
  padding: 24px;
  border-radius: 3px;
}

.sc_statistics__item {
  /* justify-content: center; */
  width: 100%;
  height: 130px;
  padding: 10px;
  padding-top: 3%;
  text-align: center;
  text-decoration: none;
  color: #394047;
  border-radius: 5px;
  box-shadow: 0 3px 6px -2px rgb(0 0 0 / 10%);
  background: #fff;
  transition: all 0.5s ease;
}

.sc_statistics__title {
  font-size: 2rem;
  font-weight: 620;  
}

.sc_statistics__title.total {
  inline-size: 170px;
}

.sc_statistics__subtitle {
  color: #394047;
  line-height: 1.6rem; 
}

.sc_statistics__table-cell .rs-table-cell-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
