.filter-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    min-width: 600px;
  }
  
  .filter-container__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  /* override rSuite */
  .rs-popover {
    padding: 20px !important;
  }
  
  .rs-modal-footer button {
    margin: 0px !important;
  }

  .filter-container .sc-panel {
      padding: 0
  }

  .ticket-filter__assigned-to {
    border-radius: 3px;
    border: 1px solid #dddedf;
  }
  