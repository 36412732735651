.status-badge {
    display: flex;
    align-items: center;
    color: #7c8b9a !important; 
    background-color: #ebeef0 !important;
    border-radius: 3px;
    padding: 5px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 3px 9px !important;
    display: inline-block;
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-word;
}

.status-badge.red {
    color: #fb2d8d !important;
    background-color: rgba(251,45,141,.2) !important;
}

.status-badge.green {
    color: #26a636 !important;
    background-color: rgb(46,208,65,.2) !important;
}

.status-badge.yellow {
    color: #15171a !important;
    background-color: #fff399 !important;
}