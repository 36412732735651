.form-container {
  display: block;
  width: 100%;
}

.form-align-right {
    text-align: right;
}

.form__file_download {
  cursor: pointer;  
}

.form__file_download:hover {
  text-decoration: underline;
}

.form-item
{
  margin: 20px 0px 10px 0px;
}

.form-item__label
{
  display:block;
}

.form-item__component--invalid
{
  border: 1px solid red !important;
}
.form-item__invalid-label
{
  color: red;
}
.form-item__component--invalid-select a
{
  color: blue;
  border: 1px solid red !important;
}

.flex-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 10px;
}

.file-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.dynamic-matrix__row {
  display: flex;
  padding: 0.5rem 0rem;
}

.dynamic-matrix__row:not(:first-child) {
  border-top: 1px solid #eaeaea;
}

.dynamic-matrix__row__fields {
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  gap: 1rem;  
}

.dynamic-matrix__row__fields__field {
  display: flex;
  flex-direction: column;
}

.dynamic-matrix__row__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.form-footer {
    display: flex;
    justify-content: space-between;
}