.sc-page-header {
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 700;
    border-bottom: 1px solid;
    border-color: transparent;
  }
  
.sc-page-header__content {
    height: 95px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sc-page-header__title {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin: -3px 0 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 3.1rem!important;
    line-height: 1.3em!important;
    font-weight: 700;
    letter-spacing: 0;
    min-height: 35px;
    color: #15171a!important;
}

.sc-page-header__title .rs-breadcrumb {
    font-size: 3.1rem!important;
    line-height: 1.3em!important;
    font-weight: 700;
    letter-spacing: 0;
    min-height: 35px;
    color: #15171a!important;
    margin: 0!important;
    padding: 0!important;
}

.sc-page-header__title .rs-breadcrumb li, 
.sc-page-header__title .rs-breadcrumb li > a {
    font-size: 3.1rem!important;
    line-height: 1.3em!important;
    font-weight: 700;
    letter-spacing: 0;
    min-height: 35px;
    color: #15171a!important;
}

.sc-page-header__title .rs-breadcrumb-separator {
    font-weight: 200!important;
}

.sc-page-header__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.sc-page--fluid .sc-breadcrumbs, 
.sc-page--fluid .rs-breadcrumb li,
.sc-page--fluid .rs-breadcrumb li > a {
    display: flex!important;
    align-items: center!important;
    font-size: 1.35rem!important;
}

.sc-page--fluid .sc-breadcrumbs__item, 
.sc-page--fluid .sc-breadcrumbs li > span {
    min-height: 33px!important;
    height: 33px!important;
    padding: 5px 12px 6px!important;
    font-weight: 400!important;
    color: #8e9cac!important;
    letter-spacing: .2px!important;
    border-radius: 3px!important;
    font-size: 1.35rem!important;
}


.sc-page--fluid .sc-breadcrumbs > .rs-breadcrumb-separator {
    display:none!important;
}

.sc-page--fluid .sc-breadcrumbs li > a {
    height: 33px!important;
    padding: 5px 12px 6px!important;
    outline: none!important;
    border: none!important;
    color: #394047!important;
    font-size: 1.35rem!important;
    font-weight: 500!important;
    letter-spacing: .2px!important;
    white-space: nowrap!important;
    background: #fff!important;
    border-radius: 3px!important;
    transition: all .25s ease!important;
    cursor: pointer;
    text-decoration: none;
    margin-right: -0.5rem;
}

.sc-page--fluid .sc-breadcrumbs li > a:hover {
    color: #394047!important;
    background: #ebeef0!important;
}

.sc-page--fluid .sc-breadcrumbs li > a::before {
    font-family: 'rsuite-icon-font' !important;
    content: "\ea0a" /* rtl: "\f104" */;
    margin-right: 1rem;
    margin-top:4px;
}

.sc-breadcrumbs__item--active span {
    display: flex;
    align-items: center;
    
}

.sc-breadcrumbs__item--active {
    padding-left:0!important;
}

