.rs-picker-disabled {
  opacity: 0.7 !important;
}

.one-value-tag-picker-menu .rs-checkbox-wrapper {
  display: none;
}

.one-value-tag-picker-menu .rs-check-item .rs-checkbox-checker > label {
  padding: 8px 12px 8px 12px;
}

.one-value-tag-picker {
  width: 100%;
}

.one-value-tag-picker .rs-tag {
  background-color: transparent;
  border: none;
}

.one-value-tag-picker i {
  display: none;
}
