.rs-flex-box-grid-item {
    margin-bottom: 1.8vw;
}

.sc-post__entry {

}
.sc-post__title {
    margin: 0 0 3px;
    font-size: 1.6rem!important;
    font-weight: 600;
    cursor: pointer;
}
.sc-post__meta {
    margin: 4px 0 0;
    font-size: 1.3rem;
    color: #abb4be;
}
.sc-post__meta strong {
    color:#8e9cac;
    font-weight: 500;
}
.sc-post__action > .rs-table-cell-content {
    display:flex;
    flex-direction: row;
    gap: 1rem;
}
.sc-page__title {
    margin: 0 0 3px;
    font-size: 1.6rem!important;
    font-weight: 600;
}
.sc-page__meta {
    margin: 4px 0 0;
    font-size: 1.3rem;
    color: #abb4be;
}
.sc-page__meta strong {
    color:#8e9cac;
    font-weight: 500;
}
.sc-page__action > .rs-table-cell-content {
    display:flex;
    flex-direction: row;
    gap: 1rem;
}
.home__sc_statistics {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 25px;
    background: #f1f3f4;
    padding: 24px;
    border-radius: 3px;
}
.home__sc_statistics__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #394047;
    border-radius: 5px;
    box-shadow: 0 3px 6px -2px rgb(0 0 0 / 10%);
    background: #fff;
    transition: all .5s ease;
}
.home__sc_statistics__title {
    font-size: 4rem;
    font-weight: 800;
}
.home__sc_statistics__subtitle {
    color: #394047;
    line-height: 1.6rem;
}

.home__sc_statistics__table-cell .rs-table-cell-content {
    display: flex;
    align-items: center;
    justify-content: center;
}


