.orgchart__container {
    position: relative;
}

.orgchart__controls {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.orgchart__controls .btn {
    margin: 0;
    text-align: center;
    border: none;
    background: #2f2f2f;
    color: #888;
    padding: 0 4px;
    margin-bottom: 5px;
    border: 1px solid #0a0a0a;
  }

.orgchart__controls .btn-lg {
font-size: 12px;
line-height: 1;
padding: 4px;
}
.orgchart__controls .btn-zoom {
    width: 26px;
  }

  .orgchart__controls  .btn-bottom {
    margin-bottom: 1rem;
  }