.tab-container
{
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    margin-bottom: 12px;
    flex-direction: column;
    justify-content: stretch;
}

.tabs 
{
    display: flex;
    justify-content: space-between;
    background: #f7f7fa;
    border-radius: 3px;
    margin-bottom: 1rem;
}

.tab-container-item
{
    margin: 3px;
    padding: 0 14px!important;
    font-size: 1.3rem!important;
    font-weight: 500!important;
    height: 28px!important;
    line-height: 28px!important;
    text-align: center;
    letter-spacing: .2px!important;
    border-radius: 3px!important;
    display: flex!important;
    align-items: center!important;
    flex-direction: row!important;
    text-overflow: ellipsis!important;
}

.tab-container-item.active
{
    background-color: #ffffff;
    border-radius: 3px;
}

.tab-container-item:not(.active)
{
    cursor: pointer;
}

.tab-container__tab {
    padding:0;
    width:100%
}

.tab-container__tab .rs-panel-body {
    width:100%
}