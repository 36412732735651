.sc-page {
    flex-grow: 1;
    padding: 0 48px 48px;
    margin: 0 auto;
    max-width: 1320px;
}

.sc-page--wide {
    max-width: 1600px;
}

.sc-page--fluid {
    max-width: 100%;
}