.sc-loader {
    position: relative;
    z-index: 2000;
    background: rgba(0,0,0, 0.2);
    width: 100%;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content: center;
}

.sc-loader--no-overlay {
    position: relative;
    z-index: 2000;
    background: transparent;
    width: 100%;
    height: 100vh;
    display:flex;
    align-items: center;
    justify-content: center;
}


.sc-loader__logo {
    width:20px;
    height:20px;
    border-radius: 50%;
    background:#000;
    box-shadow: 0 0 0 0 #0004;
    animation:pl2 1.5s infinite linear;
    position: relative;
    z-index: 2001;
}

.dark .sc-loader__logo {
    background: #fff;
    animation:pl2d 1.5s infinite linear;
}

.sc-loader__logo:before,
.sc-loader__logo:after {
    content:"";
    position: absolute;
    inset:0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #0004;
    animation: inherit;
    animation-delay: -0.5s;
}

.dark .sc-loader__logo:before {
    box-shadow: 0 0 0 0 rgba(226, 226, 226, 0.719);
}

.dark .sc-loader__logo:after {
    box-shadow: 0 0 0 0 rgba(156, 156, 156, 0.808);
}

.sc-loader__logo:after {
    animation-delay: -1s;
}

@keyframes pl2 {
    100% {box-shadow: 0 0 0 40px #0000}
}

@keyframes pl2d {
    100% {box-shadow: 0 0 0 40px rgba(63, 63, 63, 0.589)}
}