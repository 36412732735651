.ui-carousel__container {
    position: relative;
}

.ui-carousel__container__item:not(.active) {
    display: none;
}

.ui-carousel__container__fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
}

@-webkit-keyframes ui-carousel__container__fade {
    from {opacity: .4}
    to {opacity: 1}
  }
  
  @keyframes ui-carousel__container__fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  .ui-carousel__footer
  {
    margin: 5px 0px;
    text-align: center;
  }

  .ui-carousel__footer__dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .ui-carousel__footer__dot.active, .ui-carousel__footer__dot:hover {
    background-color: #717171;
  }