:root {
  /* Main dark mode colors */
  --main-white: #ffffff;

  --main-light-white: #fafafb;

  --main-dark-gray: #2b2d31;

  --main-darker-gray: #1c1e21;

  --main-light-gray: #bebebe;

  --main-dark: #151719;

  --main-dark-blue: #222933;

  --main-darker-blue: #111233;

  --main-light-dark: #292d2d;

  --main-light-dark-active: #373b43;
  /*------------------*/

  /* Dark mode dropdown */
  --dark-dropdown-bg: #212326;
  /*------------------*/

  /* Dark mode helper text */
  --dark-helper-txt-color: #8894a3;
  /* --------------------- */

  /* Dark mode primary color */
  --dark-primary-color: #30cf43;
  /* ----------------------- */

  /* Dark mode secondary color */
  --dark-secondary-color: #3498ff;
  /* ------------------------- */
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 62.5% !important;
  line-height: 1.65 !important;
  letter-spacing: 0.2px !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

body {
  color: #394047 !important;
  font-size: 1.4rem !important;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Droid Sans, Helvetica Neue, sans-serif !important;
  line-height: 1.65 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.3em;
  color: #15171a;
  line-height: 1.15em;
  text-rendering: optimizeLegibility !important;
}

html.dark {
  background: #151719 !important;
}

.dark img, 
.dark picture, 
.dark video, 
.dark .sc-page-header,
.dark .sc-sidebar__header,
.dark .rs-navbar.rs-navbar-subtle.sc-sidebar-toggle,
.dark .rs-icon.rs-icon-cog,
.dark .rs-nav-item,
.dark .sc-sidebar__item,
.dark .rs-nav-item-content,
.dark .sc-page,
.dark .rs-dropdown,
.dark .rs-btn.rs-btn-subtle.rs-dropdown-toggle,
/* Table hovering problem fix - white rows appeared */

/*                                                  */
/*.dark .rs-table-cell-content,*/
.dark .rs-modal-content,
.dark .rs-drawer-content,
.dark li.rs-breadcrumb-item.sc-breadcrumbs__item > a,
.dark .rs-table,
.dark .rs-table-row-header,
.dark .rs-table-cell,
.dark .rs-table-cell-last,
.dark .rs-dropdown-menu {
  background: var(--main-dark);
}

.rs-modal-large {
  width: min(1000px, 98vw);
}

/* Dropdown element hover/focus */
.dark
  .rs-dropdown-item:not(.rs-dropdown-item-active):hover
  > .rs-dropdown-item-content,
.dark
  .rs-dropdown-item.rs-dropdown-item-active:hover
  > .rs-dropdown-item-content,
.dark .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: var(--main-light-white) !important;
  background-color: var(--main-light-dark-active) !important;
}

.dark .user__options .rs-dropdown-menu {
  background: var(--main-darker-gray);
}

.dark
  .user__options
  .rs-dropdown-item:not(.rs-dropdown-item-active):hover
  > .rs-dropdown-item-content,
.dark
  .user__options
  .rs-dropdown-item.rs-dropdown-item-active:hover
  > .rs-dropdown-item-content,
.dark
  .user__options
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: var(--main-light-white) !important;
  background-color: var(--main-dark) !important;
}

.dark .rs-table-hover .rs-table-row:hover .rs-table-cell {
  background: var(--main-light-dark-active);
}

.dark .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.dark .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.dark .rs-table-hover .rs-table-row:hover .rs-table-cell {
  background: var(--main-light-dark-active);
}

.dark .rs-picker-toggle,
.dark .rs-picker-select-menu,
.dark .rs-picker-select-menu-item-active {
  background: var(--main-dark-blue) !important;
}

.dark .rs-avatar.rs-avatar-circle {
  border: 1px solid var(--main-dark-gray) !important;
  border-radius: 100%;
  background: var(--main-dark-gray);
}
.dark .sc-avatar {
  background: var(--main-dark-gray);
}

.dark .avatar {
  background-color: var(--main-dark-gray) !important;
  border-color: var(--main-dark) !important;
}

.dark .rs-icon-plus {
  background: var(--main-dark) !important;
}

.dark .rs-icon-plus, 
.dark .rs-icon.rs-icon-cog,
.dark .rs-dropdown-item-content,
.dark .member__name,
.dark .member__email,
.dark .rs-btn.rs-btn-subtle.rs-dropdown-toggle,
.dark p,
.dark .rs-panel-heading,
.dark .title,
.dark .home__sc_statistics__subtitle,
.dark .home__sc_statistics__title,
.dark .settings__name,
.dark .rs-icon.rs-icon-close,
.dark .row.home-external-post.header,
.dark .row.align-center,
.dark .row.home-top-post.header,

/* help center fonts */
.dark .help-section-title,
.dark .help-section a
/*                   */ {
  color: var(--main-light-white);
}

.dark .sc-sidebar__title,
.dark .rs-nav-item-content.active,
.dark .rs-picker-select-menu-item-active,
.dark label.rs-control-label.sc-form__form-label,
.dark .control-label.form-label,
.dark input.rs-input,
.dark textarea.rs-input,
.dark .sc-form__form-control,
.dark .rs-btn.rs-btn-default.sc-btn.rs-uploader-trigger-btn:hover,
.dark .rs-control-label,
.dark .ck-placeholder,
.dark
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused,
.dark .ck-placeholder,
.dark .rs-picker-select-menu-item:hover,
.dark .rs-table-cell-content,
.dark ol .rs-breadcrumb.sc-breadcrumbs,
.dark .sc-sidebar__link.active,
.dark .rs-dropdown-item-content.active,
.dark .sc-post__title,
.dark .rs-table-cell-header-icon-sort,
.dark .sc_statistics__subtitle,
.dark .rs-tag-icon-close,
.dark .rs-modal-title,
.dark .rs-modal-header-close,
.dark
  .rs-breadcrumb-item.sc-breadcrumbs__item.sc-breadcrumbs__item--active.rs-breadcrumb-item-active,
.dark .sc-table__no-data,
.dark .sc-page-header__actions,
.dark
  .rs-breadcrumb-item.sc-breadcrumbs__item.sc-breadcrumbs__item--active.rs-breadcrumb-item-active
  > span,
.dark .rs-btn.rs-btn-default.sc-btn,
.dark .rs-popover-title,
.dark .rs-breadcrumb-item.sc-breadcrumbs__item > a,
.dark li.rs-breadcrumb-separator,
.dark .rs-picker-search-bar-input,
.dark li.rs-breadcrumb-item.sc-breadcrumbs__item:hover > a,
.dark div.tabs,
.dark header.sc-panel__header,
.dark .rs-picker-daterange-header,
.dark .rs-picker-toggle-placeholder,
.dark .rs-picker-toggle-caret,
.dark
  .rs-calendar-month-dropdown-year:not(.rs-calendar-month-dropdown-year-active),
.dark .sc-label,
.dark .sc-page,
.dark .settings__title,
.dark .activity__header > h5,
.dark .cs-message-input__content-editor,
.dark .cs-message-input__content-editor::before {
  color: var(--main-light-white);
}

/* Hovering active item in sidebar */
.dark .rs-nav-item-content.active:hover,

/* Hovering any link item in help center */
.dark .help-section a:hover {
  background-color: var(--main-dark-blue) !important;
}

.dark .rs-nav-item.sc-sidebar__item > a.rs-nav-item-content,
.dark
  .rs-dropdown.sc-sidebar__item.rs-dropdown-placement-right-start.rs-dropdown-collapse,
.dark .sc-sidebar__item,
.dark a.sc-sidebar__link {
  color: var(--main-light-white) !important;
}

.dark .rs-dropdown-expand:not(.active):hover,
.dark .home__sc_statistics__item,
.dark .sc-statistics__item {
  color: var(--main-dark) !important;
  background-color: var(--main-light-white) !important;
}

.dark .home__sc_statistics__title,
.dark .home__sc_statistics__subtitle {
  color: var(--main-dark) !important;
}

.dark .home__sc_statistics,
.dark .ui-message,
.dark input.rs-input,
.dark .rs-btn.rs-btn-default.rs-picker-toggle,
.dark .color-picker,
.dark .sc-form__form-control,
.dark .rs-popover.fade.in.placement-left-start,
.dark .rs-popover.fade.placement-left-start,
.dark .rs-btn.rs-btn-default.sc-btn.rs-uploader-trigger-btn,
.dark .rs-calendar,
.dark .rs-picker-toolbar,
.dark
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused,
.dark .sc-statistics,
.dark .rs-list-item.rs-list-item-md.rs-list-item-bordered,
.dark .sc-editor-title,
.dark .rs-btn.rs-btn-default.sc-btn,
.dark .rs-tag.rs-tag-default.rs-tag-closeable:hover {
  background-color: var(--main-dark-gray);
}

.dark .rs-nav-item-content:not(.active):hover,
.dark .rs-icon-plus:not(.active):hover,
.dark .rs-icon.rs-icon-cog:hover,
.dark .sc-sidebar__item:not(.active):hover > a.sc-sidebar__link:hover,
.dark .rs-dropdown-expand:not(.active):hover {
  color: var(--main-light-white) !important;
  background-color: var(--main-dark) !important;
  text-decoration: none !important;
}

/* Sidebar right border */
.dark .rs-sidebar {
  border-right-color: var(--main-light-dark) !important;
}

/* Primary, subtle and outline buttons... */
.dark .rs-btn.rs-btn-primary.sc-btn.sc-btn--primary,
.dark .rs-btn.rs-btn-ghost.sc-btn.sc-btn--outline,
.dark .rs-btn.rs-btn-subtle.sc-btn.sc-btn--subtle {
  background-color: white !important;
  color: var(--main-darker-blue) !important;
}

.dark .box-container.bordered,
.dark .rs-panel.rs-panel-default.rs-panel-bordered,
.dark
  .sc-form__form-control.rs-picker-input.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-tag {
  border: 2px solid var(--main-dark-blue) !important;
}

/* Buttons, popover... */
.dark .rs-btn.rs-btn-subtle.rs-dropdown-toggle:focus,
.dark .rs-popover.fade.in.placement-bottom-end,
.dark .rs-popover.fade.placement-bottom-end,
.dark .rs-btn.rs-btn-default.sc-btn:hover,
.dark div.rs-popover-arrow,
.dark .settings__pages {
  background-color: var(--dark-dropdown-bg) !important;
}

/* Message component style, pagination style */
.dark .ui-message > div > h4,
.dark .rs-table-pagination-toolbar > div *,
.dark .rs-pagination-btn.rs-pagination-btn-active {
  color: white !important;
}

/* Borders and background color for all inputs */
.dark .rs-input,
.dark
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline,
.dark .rs-btn.rs-btn-default.sc-btn.sc-btn.sc-btn--image-button,
.dark .rs-picker-search-bar-input {
  background-color: var(--main-dark-gray) !important;
  border: 2px solid var(--main-dark-gray) !important;
}

/* All placeholders */
.dark ::placeholder {
  color: white !important;
}

.dark .box-container.filled,
.dark
  .rs-flex-box-grid.edit-box-gray.rs-flex-box-grid-top.rs-flex-box-grid-start,
.dark .sc-uploader-image.rs-uploader.rs-uploader-picture.rs-uploader-draggable,
.dark .sc-panel.rs-panel.rs-panel-default,
.dark .activity,
.dark .scrollbar-container.cs-message-list__scroll-wrapper.ps,
.dark .cs-message-separator,
.dark .cs-message-input,
.dark .file-preview {
  background-color: var(--main-darker-gray) !important;
}

/* Helper text blocks (usually under input element) */
.dark .rs-help-block.sc-form__help-block,
.dark .helper-text-margin-top,
.dark .helper-text.helper-text-block {
  color: var(--dark-helper-txt-color) !important;
}

/* Hover / Focus effects for input elements */
.dark input.rs-input:focus,
.dark textarea.rs-input:focus,
.dark .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred:focus,
.dark .color-picker:hover,
.dark .rs-btn.rs-btn-default.sc-btn.rs-uploader-trigger-btn:hover,
.dark .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-focused,
.dark .rs-picker-search-bar-input:focus,
.dark .sc-form__form-control.rs-picker-input.rs-picker-subtle.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-tag:hover
.dark .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block.rs-picker-has-value.rs-picker-cleanable:hover,

/* Dropdown used in Notifications => Add notification (Notification type) */
.dark .rs-picker-select.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block.rs-picker-has-value.rs-picker-cleanable:hover,
.dark .rs-picker-select.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block.rs-picker-has-value.rs-picker-cleanable:focus-within,

/* Dropdown used in Authorizations => New role (Application type) */
.dark .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block.rs-picker-has-value.rs-picker-cleanable:hover,
.dark .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block.rs-picker-has-value.rs-picker-cleanable:focus-within,

/* Dropdown used in Authorizations => New role (smaller one) */
.dark .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start:hover,
.dark .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start:focus-within {
  border: 3px solid var(--dark-primary-color) !important;
}

.dark .box-container.filled,
.dark .rs-btn.rs-btn-default.rs-picker-toggle,
.dark .rs-container.white-theme,
.dark
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-radius: 3px !important;
  border-color: var(--main-darker-gray) !important;
}

/* Textarea and picker elements */
.dark .rs-picker-toggle-value,
.dark textarea.sc-editor-title {
  color: white !important;
}

/* Image uploader element */
.dark .sc-uploader-image.rs-uploader.rs-uploader-picture.rs-uploader-draggable {
  border: 1px solid black;
  border-radius: 3px;
}

/* Picker, list item...  */
.dark .rs-list-item.rs-list-item-md.rs-list-item-bordered {
  border: 2px solid var(--main-dark-blue) !important;
}

/* Calendar, Daterange, toolbar, header */
.dark
  .rs-calendar-table-cell.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  > div,
.dark .rs-calendar-table-cell.rs-calendar-table-cell-is-today > div,
.dark .rs-calendar-table-cell.rs-calendar-table-cell-selected > div,
.dark .rs-picker-menu.fade.in.placement-bottom-start.rs-picker-date-menu,
.dark .rs-calendar-view,
.dark .rs-picker-toolbar,
.dark .rs-picker-daterange-header {
  background-color: var(--main-dark-gray) !important;
}

/* Calendar selected day,  current day */
.dark
  .rs-calendar-table-cell.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected
  > div,
.dark .rs-calendar-table-cell.rs-calendar-table-cell-is-today > div {
  color: white !important;
  border: 2px solid white !important;
}

/* Calendar selected day additional style */
.dark .rs-calendar-table-cell.rs-calendar-table-cell-selected > div {
  color: white !important;
  border: 2px solid var(--dark-secondary-color) !important;
}

/* Picker element hover over item effect */
.dark .rs-picker-select-menu-item:hover,
.dark .rs-calendar-month-dropdown-row {
  background-color: var(--main-darker-gray) !important;
}

/* Table row, dashboard top post table header, dashboard external posts header */
.dark div .rs-table-row,
.dark .row.home-top-post.header,
.dark .row.home-external-post.header {
  border-bottom: 2px solid var(--main-darker-gray);
}

/* Picker element when menu item is not in focus */
.dark
  .rs-picker-select-menu-item:not(
    .rs-picker-select-menu-item-active.rs-picker-select-menu-item-focus
  ) {
  color: var(--main-light-gray) !important;
}

/* Picker element when menu item is inot in focus and is being hovered */
.dark
  .rs-picker-select-menu-item:not(
    .rs-picker-select-menu-item-active.rs-picker-select-menu-item-focus
  ):hover {
  color: var(--main-light-white) !important;
}

.dark .sc-sidebar__item .rs-nav-item-content:hover,
.dark .sc-sidebar__item:hover {
  background-color: var(--main-light-dark-active) !important;
}

/* Dropdown  */
.dark .rs-dropdown-item.rs-dropdown-item-active > .rs-dropdown-item-content {
  background-color: var(--main-dark-blue) !important;
  color: var(--main-light-white) !important;
}

/* Tag element */
.dark .rs-tag.rs-tag-default.rs-tag-closeable,
.dark .rs-tag.rs-tag-default,
.dark .ticket__cell-status {
  background-color: black !important;
  color: var(--main-light-white) !important;
  border: 2px solid var(--main-dark-gray) !important;
}

/* Badge, */
.dark .rs-badge.status-badge.gray.rs-badge-independent,

/* Warning (white) button => "Put on hold" (ticketing) */
.dark .rs-btn.rs-btn-warning.sc-btn {
  color: black !important;
}

/* Sidebar toggle checked */
.dark span.rs-btn-toggle.rs-btn-toggle-checked,
.dark .rs-timeline-item-last .rs-timeline-item-dot::before {
  background-color: var(--dark-primary-color) !important;
}

.dark .sc-panel.rs-panel.rs-panel-default {
  border: 2px solid var(--main-darker-gray) !important;
}

.dark .rs-popover-arrow::after {
  border-top: 0;
  top: 0 !important;
  border-color: var(--main-dark) !important;
  border-bottom-color: var(--dark-dropdown-bg) !important;
}

.dark .rs-table-column-resize-spanner {
  border-left-color: var(--main-darker-gray) !important;
}

.dark div.tabs {
  background-color: var(--main-light-dark) !important;
}

.dark .tab-container-item.active {
  background-color: var(--main-light-dark-active) !important;
}

/* Date range toolbar, header, picker (some sort of autocomplete/dropdown) border  */
.dark .rs-picker-toolbar,
.dark .rs-picker-daterange-header,
.dark
  .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-block,
.dark
  .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start.rs-picker-has-value.rs-picker-cleanable,
.dark
  .rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-placement-bottom-start {
  border: 2px solid var(--main-dark-gray) !important;
}

/* Extended calendar view */
.dark .rs-calendar-month-dropdown-row,
.dark .rs-calendar-month-dropdown.show {
  border: 1px solid var(--main-dark-gray) !important;
}

.dark .rs-calendar-table-cell-content {
  color: #8e8e93;
}
.dark .rs-calendar-table-cell-un-same-month > .rs-calendar-table-cell-content {
  color: #c5c6c7;
}

/* Date range component style  */
.dark
  .rs-calendar-table-cell.rs-calendar-table-cell-in-range
  > .rs-calendar-table-cell-content {
  background-color: white !important;
  color: black !important;
  border-radius: 6px !important;
}

.dark .rs-calendar-table-cell-selected-start > .rs-calendar-table-cell-content,
.dark .rs-calendar-table-cell-selected-end > .rs-calendar-table-cell-content {
  color: #fff !important;
}

.dark .rs-picker-daterange-menu .rs-calendar:first-child {
  border-right-color: var(--main-light-dark-active);
}

.dark .rs-calendar-table-cell-content:hover {
  color: #000;
}

/* Loader component */
.dark .rs-table-loader-wrapper,

/* Main loader (fullscreen loader) */
.dark .sc-loader {
  background: var(--main-dark-gray) !important;
}

/* Sidebar dropdown menu */
.dark .rs-dropdown-item.sc-sidebar__list > .rs-dropdown-item-content.active,
.dark
  .rs-dropdown-item.sc-sidebar__list
  > .rs-dropdown-item-content:not(.active):hover {
  color: var(--main-white) !important;
}
.dark
  .rs-dropdown-item.sc-sidebar__list
  > .rs-dropdown-item-content:not(.active) {
  color: var(--main-light-gray) !important;
}
/* -------------------- */

/* Sidebar external link */
.dark .sc-sidebar__link--external,

/* Icons on chat (inquiries) => ticketing, message box */
.dark .svg-inline--fa.fa-paper-plane.fa-w-16 > path,
.dark .svg-inline--fa.fa-paperclip.fa-w-14 > path
/* ---------------------------------------- */ {
  color: var(--main-light-white) !important;
}

/* Chat module (inquiries) => ticketing, message box */
.dark .cs-message-input {
  border-top: 2px solid var(--main-light-dark-active) !important;
}

/* Chat module (inquiries) => ticketing, message box text */
.dark .rs-divider-inner-text,
.dark .rs-divider-inner-text > small > em > span {
  color: var(--main-light-white) !important;
}

/* Chat module (inquiries) => ticketing, message box before and after dividers */
.dark .cs-message-separator::before,
.dark .cs-message-separator::after {
  background: var(--main-light-white) !important;
}

.dark .cs-message-input__content-editor-wrapper,
.dark .cs-message-input__content-editor {
  background: var(--main-dark-gray) !important;
}

/* Ticketing  (inquiries) page divider */
.dark .rs-divider.rs-divider-horizontal {
  border: 1px solid var(--main-darker-gray) !important;
}

.dark .modal-confirm__body {
  color: #fff !important;
}

.rs-btn.rs-btn-default.rs-picker-toggle {
  background-color: #f7f7fa;
}
.dark .rs-btn.rs-btn-default.rs-picker-toggle {
  background: var(--main-dark-gray) !important;
}

/* Query builder */
.dark .ruleGroup-combinators,
.dark .rule-fields,
.dark .rule-operators,
.dark .rule-value,
.dark .rule-remove,
.dark .ruleGroup-remove {
  background: var(--main-dark-gray) !important;
  border: var(--main-dark-gray);
}

.dark .rule-remove {
  border-radius: 5px;
}
/* -------------- */

.dark .sc-page__title,
.dark .notification-title {
  color: #fff !important;
}

/* Unread notification badge - My notifications screen */
.dark .rs-badge.rs-badge-independent.rs-badge-dot {
  background: #fff !important;
}

/* Modal */
.dark .rs-modal-wrapper {
  color: var(--main-light-white) !important;
}

.sc-one-app {
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
}

.sc-viewport {
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  height: 100%;
}

.sc-one-app .rs-sidebar {
  position: relative;
  z-index: 800;
  flex: 0 0 320px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  transform: translateX(0);
  border-right: 1px solid #e6e9eb;
}

.sc-main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.sc-page-content {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.sc-drawer-elements-margin-top {
  margin-top: 20px;
}

/*
input:focus, textarea:focus {
  outline: 0;
    border-color: #30cf43!important;
    box-shadow: inset 0 0 0 1px #30cf43;
}*/

.edit-box-gray {
  background-color: #f9f9fa;
  padding: 15px;
  border-radius: 5px;
  gap: 10px 0px;
}

.edit-box-gray__title {
  font-weight: bold;
}

.ticket {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
}

.ticket__request {
  padding: 10px 20px;
  border-radius: 10px;
  flex-basis: 80%;
}

.ticket__time {
  flex: 0 0 100%;
  color: gray;
  font-size: 1.1rem;
  margin: 4px 0px;
}

.ticket__start {
  justify-content: flex-start;
}

.ticket__start .ticket__request {
  background-color: #ddebff;
  border: 1px solid #ddebff;
}

.ticket__end {
  flex-direction: row-reverse;
  align-content: flex-end;
  text-align: right;
}

.ticket__end .ticket__request {
  background-color: #fffce6;
  border: 1px solid #fffce6;
}

.ticket__end .ticket__time i {
  margin-left: 4px;
}

.ticket__avatar {
  flex: 0 0 4em;
}

.ticket__status {
  padding: 10px 20px;
  background-color: #f9f9fa;
  border: 1px solid #f9f9fa;
  border-radius: 10px;
}

.ticket__info {
  padding: 10px 20px;
  background-color: #f9f9fa;
  border: 1px solid #f9f9fa;
  border-radius: 10px;
}

.ticket__info__content {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.rs-btn-subtle:focus,
.rs-btn-subtle.rs-btn-focus,
.rs-btn-subtle:hover {
  background: #f1f3f4 !important;
}

.rs-btn-default,
.rs-btn-primary,
.rs-btn-subtle {
  overflow: visible !important;
}

.rs-flex-box-grid {
  padding: 0;
}

.aside__box-clear {
  margin-bottom: 3.5rem;
}
.aside__box-clear .rs-panel-body {
  width: 100% !important;
}
.aside__box-clear .sc-form__form-group {
  margin-bottom: 1rem !important;
}
.aside__box-clear .rs-picker-tag {
  border-radius: 3px !important;
}
.aside__box-clear .sc-panel__header {
  display: block;
  font-size: 1.7rem;
  margin: 0 0 0.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ebeef0;
}

.aside__box-clear .rs-picker-select {
  background: #f7f7fa;
  border-radius: 3px;
}

.rs-input {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  border: 1px solid #dddedf;
  background: #fff;
  color: #394047;
  font-size: 1.5rem;
  font-weight: 400;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  border-radius: 3px;
  -webkit-appearance: none;
}

.rs-tag {
  /*
  margin: 2px;
  padding: 2px 6px;
  */
  border-radius: 3px;
  font-size: 0.93em;
  font-weight: 500;
  border: 1px solid #e6e9eb;
  color: #394047;
  background: #e6e9eb;
}

.rs-tag-icon-close {
  margin-top: 2px;
}

.link__green {
  color: #30cf43;
}

.link__green:hover {
  cursor: pointer;
  text-decoration: underline;
}

.box-container {
  border-radius: 3px;
  padding: 24px;
}

.box-container-title {
  text-transform: uppercase;
  font-size: 1.1rem;
  letter-spacing: 0.2px;
  font-weight: 500;
}

.box-container-list > div:not(:first-child) {
  margin-top: 12px;
}

.box-container.bordered {
  border: 1px solid #ebeef0;
}

.box-container.filled {
  border: 1px solid #f5f6f6;
  background-color: #f5f6f6;
}

.box-container .title {
  font-size: 1.65rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 8px;
  color: #15171a;
}
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.sc-notification .rs-notification-item-content,
.sc-notification .rs-notification-title-with-icon {
  background-color: black;
  color: white;
}

.rs-picker-menu {
  z-index: 1061;
}

.table-like-container .row {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  padding: 13px 0px;
}

.table-like-container .row:not(:last-child) {
  border-bottom: 1px solid rgb(242, 242, 245);
}

.dark .table-like-container .row:not(:last-child) {
  border-bottom: 1px solid var(--main-dark-blue) !important;
}

.table-like-container .row.header {
  color: #8e8e93;
  font-size: 0.9em;
}

.table-like-container .row.home-external-post {
  grid-template-columns: 4fr 2fr 0.5rem;
}

.table-like-container .row.home-top-post {
  grid-template-columns: 3fr 1fr;
}

.rs-notification-description {
  color: #ccc;
}

.rs-picker-select a {
  border-radius: 3px;
}

.mt-24 {
  margin-top: 24px;
}

.filter-container {
  display: grid;
  grid-template-columns: repeat(1fr 1fr);
  gap: 10px;
}

.filter-container__span_columns {
  grid-column-start: 1;
  grid-column-end: 3;
}

.filter-container__buttons {
  display: flex;
  justify-content: flex-end;
}

.dark .cs-message__sender-name,
.dark .cs-message__sent-time {
  color: var(--main-light-gray) !important;
}

.dark .attachment-btn {
  color: var(--main-light-gray) !important;
}

.dark .sc-panel__header {
  border-bottom: 1px solid var(--main-light-dark) !important;
}

.dark .rs-timeline-item-tail {
  background-color: var(--main-light-dark);
}

.dark .rs-btn-subtle:hover {
  color: var(--main-dark) !important;
}

.dark a {
  color: mediumaquamarine;
}

.dark .rs-modal-backdrop.in {
  background: darkgray;
}

.dark .sc-page-header__title .rs-breadcrumb li,
.dark .sc-page-header__title .rs-breadcrumb li > a {
  color: var(--main-white) !important;
}

.dark .rs-pagination > li > a.rs-btn-focus,
.dark .rs-pagination > li > a:focus,
.dark .rs-pagination > li > a:hover {
  color: var(--main-dark) !important;
}

.dark .rs-pagination > li > a.rs-btn-focus,
.dark .rs-pagination > li > a:focus,
.dark .rs-pagination > li.rs-pagination-btn-active > a:hover {
  color: var(--dark-primary-color) !important;
}

.dark .rs-btn-subtle:focus {
  color: var(--main-light-white) !important;
}

.dark .sc-page--fluid .sc-page-header__title .rs-breadcrumb li,
.dark .sc-page--fluid .sc-page-header__title .rs-breadcrumb li > a {
  color: var(--main-dark) !important;
}

.dark .sc-page--fluid .rs-btn.rs-btn-ghost.sc-btn.sc-btn--outline,
.dark .sc-page--fluid .rs-btn.rs-btn-subtle.sc-btn.sc-btn--subtle {
  color: var(--main-light-white) !important;
  background: var(--main-dark) !important;
}

.dark .sc-post-publish-settings__section,
.dark .sc-page-publish-settings__section {
  border-top-color: var(--main-light-dark-active);
  border-bottom-color: var(--main-light-dark-active);
}

.dark .sc-post-publish-settings__heading,
.dark .sc-post-publish-settings__option,
.dark .sc-page-publish-settings__heading,
.dark .sc-page-publish-settings__option {
  color: #fff;
}

.dark .sc-post-publish-settings__description,
.dark .sc-page-publish-settings__description {
  color: var(--main-light-gray);
}

.dark .sc-btn--outline {
  color: var(--main-light-gray);
}
.dark .sc-btn--outline:hover {
  color: var(--main-white) !important;
}

.dark .cs-message-input__content-editor[data-placeholder]:empty:before {
  color: var(--main-light-gray);
}

.rs-table-cell-wrap {
  align-items: center;
  display: flex;
  height: 100%;
}

/* UTILITY CLASSES */
.d-flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.justify-end {
  justify-content: end;
}
.align-center {
  align-items: center;
}
.gap-2 {
  gap: 1rem;
}

/* SURVEY JS */
.sd-input.sd-input--disabled,
.sd-input.sd-input--disabled::placeholder,
.sd-item--disabled .sd-item__control-label,
.sd-boolean--disabled .sd-boolean__thumb,
.sd-boolean--disabled .sd-boolean__label {
  opacity: 0.9;
}

.sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  fill: black;
}
