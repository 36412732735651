.sc-uploader-image {
    background:#f7f8f9;
    min-height: 150px;
    display: flex!important;
    align-items:center;
    justify-content: center;
    border: 1px solid #dddedf;
    border-radius: 3px;
}

.sc-uploader-image__uploader-trigger {


}

.sc-uploader-image--with-image {
    min-height: 50px;
    max-height: 250px;
}

.sc-uploader-image--with-image__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; /* or object-fit: contain; */
    max-height: 250px;
}