.sc-table__header-cell {
   
    letter-spacing: .1px;
    color: #15171a;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-word;
}

.sc-table__no-data {
    text-align: center;
    padding: 60px 0px;    
}

.sc-table__no-data__error {
    color: #f44336;
}

.sc-table__add-new {
    margin-left: 10px;
}

.sc-table__cell {
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-word;
}

.rs-table-cell-content {
    word-wrap: break-word!important;
    white-space: break-spaces!important;
    word-break: break-word!important;
    
}

.sc-table__header-cell.small > .rs-table-cell-content{
    font-size: small;
}