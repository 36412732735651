.ticket__cell-status {
    word-wrap: break-word;
    white-space: nowrap;
    word-break: break-word;
    text-transform: uppercase;
    font-size: smaller;
    font-weight: 500;
    color: #15171a;
    border: 1px solid #ddd;
    padding: 3px 9px;
}

.ticket__cell-status--closed {
    color: #abb4be;
}

.sc-picker-disabled {
    opacity: 1!important;
}

.sc-picker-disabled .rs-picker-toggle-caret {
    display: none;
}

.sc-picker-disabled .rs-picker-toggle-value {
    color: #394047!important
}

.dark .sc-picker-disabled .rs-picker-toggle-value {
    color: #fff!important
}

.ticket__title {
    cursor:pointer;
    display:flex;
    flex-direction: column;
}

.ticket__title .rs-badge-content {
    background: #f44336!important;
}