.sc-sidebar {
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  line-height: 1.6em;
  margin: 0;
  /* overflow: auto;*/
  margin-bottom: 65px;
}

.sc-app-sidebar {
  display: flex;
  flex-direction: column;
}

.sc-app-sidebar--expanded {
  width: 320px !important;
}

.sc-app-sidebar--collapsed {
  width: 57px !important;
}

.sc-sidebar.rs-sidenav-collapse-in {
  overflow: auto;
}

.sc-sidebar__item {
  line-height: 1.4em;
  margin: 0;
  padding: 0;
}

.sc-sidebar__header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 96px;
  padding: 32px;
}

.sc-sidebar__details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
  min-width: 0;
}

.sc-sidebar__logo {
  flex-shrink: 0;
  margin-right: 10px;
  width: 32px;
  height: 32px;
  background-color: transparent;
  background-size: 32px;
  border-radius: 6px;
}
.sc-sidebar__logo img {
  width: 100%;
}

.sc-sidebar__title {
  font-size: 1.5rem;
  line-height: 1.3em;
  font-weight: 700;
  color: #15171a;
}

.sc-sidebar__list,
.sc-sidebar .rs-nav-item-content,
.sc-sidebar .rs-dropdown .rs-dropdown-toggle {
  transition: none !important;
}

.sc-sidebar .rs-nav-item > .rs-nav-item-content,
.sc-sidebar .rs-dropdown .rs-dropdown-toggle,
.sc-sidebar .rs-dropdown-item > .rs-dropdown-item-content {
  color: #4b545d !important;
}

.sc-sidebar__item--external .sc-sidebar__link--external {
  color: #1675e0 !important;
}

.sc-sidebar .rs-nav-item > .rs-nav-item-content:hover,
.sc-sidebar .rs-dropdown-item > .rs-dropdown-item-content:hover,
.sc-sidebar .rs-dropdown .rs-dropdown-toggle:hover {
  background: #f1f3f4 !important;
}

.sc-sidebar.rs-sidenav-collapse-in .rs-nav-item .rs-nav-item-content > .rs-icon,
.sc-sidebar.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle > .rs-icon {
  left: 39px !important;
  top: unset;
}

.rs-sidenav-collapse-in .sc-sidebar__item > .rs-dropdown-menu {
  width: 100% !important;
}

.sc-sidebar .rs-dropdown-menu.rs-dropdown-menu-collapse-in {
  margin-bottom: 2rem;
}

.sc-sidebar__item > .rs-nav-item-content.active,
.sc-nav-item-title-link.active,
.sc-sidebar .rs-dropdown-item > .rs-dropdown-item-content.active {
  font-weight: 700 !important;
  color: #15171a !important;
}

.sc-sidebar .rs-dropdown-toggle-caret {
  right: unset !important;
  left: 10px;
  opacity: 0.4;
}

.sc-sidebar__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sc-sidebar__icon {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 19px;
}

.sc-sidebar__text {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
}

.sc-sidebar__icon > .rs-icon,
.sc-nav-item-icon--collapsed > .rs-icon {
  font-size: 16px !important;
}

.sc-sidebar__icon--collapsed {
  padding: 15px;
  padding-left: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sc-sidebar__action {
  margin-right: 15px;
}

.sc-sidebar__link {
  color: #4b545d !important;
  position: relative;
}

.sc-sidebar__item > .rs-nav-item-content,
.sc-sidebar__list > a {
  display: flex !important;
  align-items: center;
  transition: none !important;
  font-weight: 400 !important;
  padding: 7px 32px 7px 39px !important;
  
  margin: 0 !important;
  border-radius: 0 !important;
  box-sizing: border-box !important;
  line-height: 1.4;
  padding-left: 74px !important;
}

.rs-sidenav-collapse-out .sc-sidebar__list > a {
  padding-left: 3rem !important;
}

.sc-sidebar__item--last {
  margin-bottom: 2rem;
}

.sc-sidebar__link.active {
  font-weight: 700 !important;
  color: #15171a !important;
}

.sc-sidebar .rs-dropdown-menu-toggle-icon {
  left: 15px !important;
  top: unset;
  position: absolute;
}

.sc-sidebar .rs-dropdown-collapse .rs-dropdown-menu-toggle-icon::before {
  content: "\f105" /* rtl: "\f104" */;
}

.sc-sidebar .rs-dropdown-expand .rs-dropdown-menu-toggle-icon::before {
  content: "\f107";
}

.sc-sidebar__action--no-action {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-theme {
  display: flex;
  align-items: center;
  height: 4rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
}

.sc-theme__toggle {
  background-color: #000 !important;
}

.sc-theme__toggle:hover {
  background-color: #000 !important;
}

.sc-avatar {
  margin-left: 1.5rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.rs-sidenav-collapse-out .sc-sidebar__logo {
  margin-left: -20px;
}

.sc-app-sidebar--collapsed .sc-avatar {
  margin-left: 0.7rem;
}

.sc-app-sidebar--collapsed .sc-theme {
  margin-right: 0.5rem;
}

.sc-app-settings {
  display: flex;
  align-items: center;
}

.sc-app-settings .sc-app-settings__toggle {
  margin-left: 1rem;
  margin-right: 2rem;
  margin-top: 0.5rem;
}

.sc-app-sidebar--collapsed .sc-app-settings .sc-app-settings__toggle {
  margin-right: 1rem;
}

.sc-sidebar-toggle {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.sc-sidebar-toggle--mobile {
  padding-left: 70px;
  z-index: 100;
}

.sc-sidebar-toggle .rs-nav-horizontal .sc-avatar {
  margin-top: 0.3rem;
}

.sc-sidebar-toggle--mobile .rs-nav-horizontal .sc-avatar {
  margin-left: 0;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}
