.color-picker {
    display: flex;
    flex-direction: row;
    align-items:center; 
    justify-content:flex-start; 
    align-self: stretch;
    height: 38px; 
    background: #fff; 
    border: 1px solid #dddedf;
    width: 100%;
    height: 38px;
    padding: 6px 16px;
    color: #394047;
    font-size: 1.5rem;
    font-weight: 400;
    user-select: text;
    border-radius: 3px;
    overflow:hidden;
}

.color-picker__swatch {
    width: calc(35% + 10px);
    min-width: 30px;
    height:38px;
    margin-left: -16px; 
    background: '#fff';
    border-right: 1px solid #dddedf;
    padding: 3px;
    margin-right: 5px;
}