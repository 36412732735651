.sc-panel {
    border: 1px solid #e5eff5;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgb(3 27 78 / 5%);
    color: inherit;
    display: flex;
    padding: 20px 25px 25px;
    position: relative;
    text-decoration: none;
}

.sc-panel__header {
    display: block;
    font-size: 1.7rem;
    margin: 0 0 .5rem;
    font-weight: 600;
}