.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-hr {
  background-image: url(../Images/flags/4x3/hr.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(../Images/flags/1x1/hr.svg);
}
.flag-icon-de {
  background-image: url(../Images/flags/4x3/de.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(../Images/flags/1x1/de.svg);
}
.flag-icon-it {
  background-image: url(../Images/flags/4x3/it.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(../Images/flags/1x1/it.svg);
}
.flag-icon-en {
  background-image: url(../Images/flags/4x3/en.svg);
}
.flag-icon-en.flag-icon-squared {
  background-image: url(../Images/flags/1x1/en.svg);
}