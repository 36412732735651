.sc-btn {
    overflow: hidden!important;
    padding: 0 14px!important;
    font-size: 1.35rem!important;
    font-weight: 500!important;
    height: 34px!important;
    line-height: 34px!important;
    text-align: center;
    letter-spacing: .2px!important;
    border-radius: 3px!important;
    display: flex!important;
    align-items: center!important;
    flex-direction: row!important;
    text-overflow: ellipsis!important;
}

.sc-btn--image-button {
    padding: 14px!important;
    height: 34px!important;
}

.sc-btn--subtle {
    background: none!important;
}
.sc-btn--subtle:hover {
    background: #ebeef0 !important;
}
.sc-btn > .rs-dropdown-toggle-caret {
    position: unset;
}
.sc-btn--outline {
    color: #394047!important;
    fill: #394047!important;
    border: 1px solid #dee3e7!important;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%)!important;
    background: none!important;
}
.sc-btn--outline:hover {
    color: #15171a!important;
    background: none!important;
    border-color: #ced4d9!important;
}
.sc-btn--primary {
    color: #fff!important;
    background: #15171a!important;
}
.sc-btn--primary:hover {
    color: #fff!important;
    background: #000!important;
}
.sc-btn--danger {
    color: #fff!important;
    background: #f44336!important;
}
.sc-btn--danger:hover {
    color: #fff!important;
    background: #f44336!important;
}

.sc-btn--trigger {
    color: #fff!important;
    background: #30cf43!important;
}
.dark .sc-btn--outline {
    color: var(--main-light-gray)!important;
}
.dark .sc-btn--outline:hover {
    color: var(--main-white)!important;
}