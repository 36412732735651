
.member {
    /*
    border-top: 1px solid #ebeef0;
    padding: 32px 0 0;
    margin-bottom: 3vw;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.member__name {
    font-size: 1.7rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.member__email {
    color: #15171a;
}
.member__created {
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    color: #7c8b9a;
    margin-top:-3px;
}