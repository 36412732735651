.sc-form__form-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sc-form__form-control {
  display: flex !important;
  align-self: stretch;
  width: 100% !important;
}

.sc-form__form-label {
  display: block;
  color: #15171a;
  font-size: 1.3rem;
  font-weight: 500;
}

.sc-form__help-block {
  margin: 4px 0 0 !important;
  color: #697989 !important;
  font-size: 1.25rem !important;
  font-weight: 400 !important;
}

.sc-form__badge {
  padding: 4px 12px;
  height: 34px;
  border-radius: 3px;
}

.sc-form__badge__blue {
  background-color: #ddebff;
  color: #3a8cff;
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  width: 100%;
}
